<template>
  <div
    v-on-click-outside="closeMenu"
    data-cy="notes-dropdown"
    class="relative flex flex-col flex-1"
  >
    <button
      type="button"
      aria-haspopup="listbox"
      aria-expanded="true"
      aria-labelledby="listbox-label"
      class="relative w-full pl-2 pr-8 h-9 text-left text-sm rounded capitalize py-1 text-gray-800 bg-white border"
      :class="[
        showMenu
          ? 'border-blue-500 hover:border-blue-500'
          : 'hover:border-gray-300'
      ]"
      @click.stop="toggleMenu"
    >
      <div class="flex items-center space-x-2">
        <div>
          <div
            class="h-1-5 w-1-5 rounded-full"
            :style="`background-color:rgb(${getBackgroundColor(selectedVal)})`"
          />
        </div>
        <div
          v-tooltip="{
            content: selectedOption,
            placement: 'left',
            onShow: () => selectedOption || selectedOption.length >= 25
          }"
          class="ml-1 ellipsis"
        >
          {{ selectedOption || placeholder }}
        </div>
      </div>
      <span
        class="ml-4 absolute inset-y-0 right-0 flex items-center pr-1 cursor-pointer mt-2"
      >
        <icon-base
          v-if="showMenu"
          class="mr-2 text-gray-600"
          :icon="IconArrowDown"
        />
        <icon-base
          v-if="!showMenu"
          class="mr-2 text-gray-600"
          icon="arrow-up"
        />
      </span>
    </button>
    <ul
      v-show="showMenu"
      tabindex="-1"
      role="listbox"
      aria-labelledby="listbox-label"
      aria-activedescendant="listbox-item-3"
      class="max-h-59 top-13 left-0 overflow-y-auto w-full z-50 absolute bg-white shadow-lg rounded-md pt-2 text-sm ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
    >
      <li
        v-for="(option, index) in options"
        :key="option.id"
        v-tooltip="{
          content: option.name,
          placement: 'left',
          onShow: () => option.name.length > 30
        }"
        class="py-2 pl-3 flex justify-start overflow-auto font-normal capitalize items-center"
        :aria-selected="isSelected(option.id)"
        :class="[
          { 'bg-gray-100': isSelected(option.id) },
          isPhaseActive(index)
            ? 'text-gray-600 hover:bg-gray-100 cursor-pointer'
            : 'text-gray-400 cursor-not-allowed'
        ]"
        :data-cy="`notes-dropdown-option-${index}`"
        @click="isPhaseActive(index) && selectOption(option.id)"
      >
        <div
          class="h-1-5 w-1-5 rounded-full"
          :style="
            isPhaseActive(index)
              ? `background-color:rgb(${getBackgroundColor(option.id)})`
              : `background-color:rgb(${COLORS[15]})`
          "
        />
        <span class="ml-1 ellipsis whitespace-nowrap">
          {{ option.name }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { COLORS } from "@/helpers/constants";
import sample from "lodash/sample";
import { useRoute } from "vue-router";
import { ROUTE_DEALS } from "@/router/routes";

export interface Option {
  id: string;
  name: string;
}

interface Props {
  modelValue?: string;
  options: Option[];
  placeholder?: string;
  colorsForOptions?: Record<string, number>;
  disabled?: boolean;
  activeFlowIndex?: number;
}
</script>

<script setup lang="ts">
import IconArrowDown from "@/components/icons/IconDownArrow.vue";
const props = defineProps<Props>();
const emit = defineEmits<{
  "update:modelValue": [value: string];
}>();

const route = useRoute();

const showMenu = ref(false);
const selectedVal = ref(props.modelValue || "");

const selectedOption = computed(() => {
  const option = props.options?.find(
    (option) => option.id === selectedVal.value
  );
  return option?.name ?? "";
});
const isDealsList = computed(() => route.name === ROUTE_DEALS);

const isSelected = (id: string) => selectedVal.value === id;

const isPhaseActive = (phaseIndex: number) => {
  const isForDealNotes = props.activeFlowIndex !== undefined;
  return (
    phaseIndex === 0 ||
    !isForDealNotes ||
    isDealsList.value ||
    (isForDealNotes && phaseIndex <= props.activeFlowIndex)
  );
};

const selectOption = (id: string) => {
  if (props.disabled) {
    return;
  }
  selectedVal.value = id;
  toggleMenu();
  emit("update:modelValue", id);
};

const toggleMenu = () => {
  showMenu.value = !showMenu.value;
};

const closeMenu = () => {
  showMenu.value = false;
};

const getBackgroundColor = (id: string) => {
  if (props.colorsForOptions) {
    const option = props.colorsForOptions[id];
    return COLORS[option];
  }
  return sample(COLORS);
};

onMounted(() => {
  if (
    !selectedVal.value &&
    props.activeFlowIndex !== undefined &&
    props.activeFlowIndex >= 0 &&
    props.options.length
  ) {
    selectedVal.value = props.options[props.activeFlowIndex].id;
    emit("update:modelValue", selectedVal.value);
  }
});

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue) {
      selectedVal.value = newValue;
    }
  }
);
</script>

<style scoped>
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
